import { customFetch, customFetchFile, customDeleteFetch } from "../../../app/functions/CommonFunctions";
const REACT_APP_API_BASE_URI = process.env.REACT_APP_API_BASE_URI;
const API_BASE_URI = REACT_APP_API_BASE_URI;
  // window.location.hostname !== "localhost"
  //   ? window.location.origin + "/jepc"
  //   : REACT_APP_API_BASE_URI;

/**-----  GET DRAFTED DATA--- */
export function fetchDraftedData(requestId = null) {
  return customFetch(`${API_BASE_URI}/paq/getPaqDraftData/${requestId}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchFmDraftedData(requestId = null) {
  return customFetch(`${API_BASE_URI}/fm/getFmDraftData/${requestId}`)
    .then((res) => {
      if (res.ok || res.status === 404) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
/*----FOR GENERAL USAGE----*/
export function fetchPreDefinedData(gpid = 1, requestId = 1) {
  return customFetch(
    `${API_BASE_URI}/paq/getFieldsFromEC/userId/${gpid}/${requestId}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

/*----POSITION DETAILS RELATED APIS----*/
//1. get position details master
export function fetchPositionDetailsMaster() {
  return customFetch(`${API_BASE_URI}/paq/getDropdowns/positionDetails`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
//2. Get position details pre defined
export function fetchPositionDetails(gpid = 1, requestId = 1) {
  return customFetch(
    `${API_BASE_URI}/paq/getFieldsFromEC/userId/${gpid}/${requestId}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
//3. Submit position details
export function postPositionDetails(payload: any) {
  return customFetch(
    `${API_BASE_URI}/paq/insert/positiondetails`,
    "post",
    payload
  )
    .then((res) => {
      if (res.status === 422) window.location.reload();
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
//3. Getting subfunctions from function
export function fetchSubFunctionsDropdown(functionCode: any) {
  return customFetch(
    `${API_BASE_URI}/paq/getDropdowns/subFunctions/${functionCode}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchSectorsDropdown(countryCode: any) {
  return customFetch(`${API_BASE_URI}/paq/getDropdowns/sectors/${countryCode}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchDivisionsDropdown(sectorCode: any) {
  return customFetch(`${API_BASE_URI}/paq/getDropdowns/divisions/${sectorCode}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchRegionsDropdown(divisionCode: any) {
  return customFetch(`${API_BASE_URI}/paq/getDropdowns/regions/${divisionCode}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchBusinessUnitsDropdown(regionCode: any) {
  return customFetch(
    `${API_BASE_URI}/paq/getDropdowns/businessUnits/${regionCode}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchMarketUnitsDropdown(businessUnitCode: any) {
  return customFetch(
    `${API_BASE_URI}/paq/getDropdowns/marketUnits/${businessUnitCode}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function fetchWorkLocationsDropdown(marketUnitCode: any) {
  return customFetch(
    `${API_BASE_URI}/paq/getDropdowns/workLocations/${marketUnitCode}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
/*----ROLE CONTEXT APIS----*/
export function fetchRoleContextMaster() {
  return customFetch(`${API_BASE_URI}/paq/getDropdowns/roleContexts/3`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
export function postRoleContext(payload: any, fmType: any) {
  return customFetch(
    `${API_BASE_URI}/fm/insert/applicableQuestionsResponse/${fmType}`,
    "post",
    payload
  )
    .then((res) => {
      if (res.status === 422) window.location.reload();
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

// Role Context Api
// Fetch Role Context Form
export function fetchRoleContextForm(fmType = null) {
  return customFetch(`${API_BASE_URI}/fm/getApplicableQuestions/${fmType}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
// future organization chart api
export function uploadFutureOrganizationChart(payload: any) {
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("requestId", payload.requestId);
  formData.append("user", payload.user);
  return customFetchFile(
    `${API_BASE_URI}/paq/insert/futureOrganizationChart`,
    formData
  )
    .then((res) => {
      if (res.status === 422) window.location.reload();
      if (res.status === 201) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
// update lock/unlock status
export function postLockUnlockStatus(payload: any) {
  return customFetch(`${API_BASE_URI}/updateStatus`, "post", payload)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

// get TR additional details
export function fetchTrAdditionalDetails(
  functionCode = null,
  subFunctionCode = null,
  requestId = null
) {
  return customFetch(
    `${API_BASE_URI}/getTrAdditionalDetails/${functionCode}/${subFunctionCode}/0/${requestId}/fmType`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

// generate PDF
export function postGeneratePdf(payload: any) {
  return customFetch(
    `${API_BASE_URI}/generateQuestionnairePdf`,
    "post",
    payload
  )
    .then((res) => {
      if (res.status === 422) window.location.reload();
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

// customFetch job code
export function fetchFutureJobCode(
  functionCode = null,
  subFunctionCode = null,
  evaluatedLevelId = null,
  requestId = null
) {
  return customFetch(
    `${API_BASE_URI}/getTrAdditionalDetails/${functionCode}/${subFunctionCode}/${evaluatedLevelId}/${requestId}/paqFormType`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

// save TR result
export function postTrHr(payload: any) {
  return customFetch(`${API_BASE_URI}/createTrHr`, "post", payload)
    .then((res) => {
      if (res.ok) {
        return res;
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

/**-----  GET Manager name--- */
export function fetchManagerName(gpid = null) {
  return customFetch(`${API_BASE_URI}/getManagerName/${gpid}`)
    .then((res) => {
      if (res.ok || res.status === 500) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

/**-----  GET Form Status--- */
export function fetchFormStatus(requestId = null) {
  return customFetch(`${API_BASE_URI}/checkStatusOfForm/${requestId}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

// fetch query params
export function fetchQueryParams(payload: any) {
  return customFetch(`${API_BASE_URI}/request/user-details`, "post", payload)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

//get user name from GPID
export function fetchUserNameFromGpid(gpid = 1) {
  return customFetch(`${API_BASE_URI}/getManagerName/${gpid}`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}


//verify share 
export function verifyShare(filterName = "",filterValue = "") {
  return customFetch(
    `${API_BASE_URI}/paq/getEmpDetailsEC/${filterName}/${filterValue}`,
    "get")
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

//submit share api
export function submitShare(payload: any) {
  return customFetch(
    `${API_BASE_URI}/paq/updateShraredWith`,"post",payload)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}

//remove from share
export function deleteFromShare(gpid = 1, requestId = 1) {
  return customDeleteFetch(`${API_BASE_URI}/paq/removeShraredWith/${requestId}/${gpid}`).then((res) => {
      if (res.ok) {
        return res;
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
}
