import React, { useEffect } from "react";
import "../../assets/css/header/header.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useAppDispatch } from "../../app/hooks";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslated } from "../../app/functions/getTranslatedLevel";
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

interface IHeader {
  updateLanguage: Function;
  state: any;
}
interface CustomWindow extends Window {
  WalkMeAPI: any;
}
declare const window: CustomWindow;
const Header = ({ updateLanguage, state }: IHeader) => {
  const { i18n } = useTranslation();
  const appDispatch = useAppDispatch();
  const language = state.language
  const isEnable = state.oktaUserRole == "tr" && state.showLandingPage
  const helpLink = "https://pepsico.service-now.com/esc?id=sc_cat_item&sys_id=21cfcd83db05e850bad55278dc9619e2";
  useEffect(() => {
    if (window.WalkMeAPI && window.WalkMeAPI.changeLanguage) {
      window.WalkMeAPI.changeLanguage(language);
    }
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const languageOptions = [
    { name: "English", value: "en" },
    // { name: "Español", value: "es" },
    { name: "Español (es-MX)", value: "es-MX" },
    // { name: "Português (pt-BR)", value: "pt-BR" },
    // { name: "Русский (ru)", value: "ru" },
    // { name: "中文 (zh)", value: "zh" },
    // { name: "Dutch (nl)", value: "nl" },
    // { name: "français (fr)", value: "fr" },
    // { name: "français (fr-CA)", value: "fr-CA" },
    // { name: "Deutsche (de)", value: "de" },
    // { name: "Ελληνικά (el)", value: "el" },
    // { name: "عربى (ar)", value: "ar" },
    // { name: "italiano (it)", value: "it" },
    // { name: "Polski (pl)", value: "pl" },
    // { name: "Română (ro)", value: "ro" },
    // { name: "Srpski (sr)", value: "sr" },
    // { name: "ไทย (th)", value: "th" },
    // { name: "Türkçe (tr)", value: "tr" },
    // { name: "Tiếng Việt (vi)", value: "vi" }

  ];

  const updateFormData = (params: any) => {
    appDispatch(params);
  };

  const updateState = (payload: object) => {
    updateFormData(updateLanguage(payload));
  };

  const handleChange = (key: any, event: any) => {
    //console.log(key, event);
    let value = event.target.value;
    if (window.WalkMeAPI && window.WalkMeAPI.changeLanguage) {
      window.WalkMeAPI.changeLanguage(language);
    }
    i18n.changeLanguage(value)
    updateState({
      [key]: value,
    });
  };

  return (
    <>
      <header className="header-wrapper" lang="">
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <div className="logo-area">
                {/* <Link to="/"> */}
                <img src="/images/pepsico-logo.jpg" className="logo" />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              justifyContent="right"
              alignItems="center"
              display={"flex"}
            >
              <div style={{ paddingRight: '2em' }}>
                <Select
                  // disabled={!isEnable} 
                  SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                  sx={{
                    backgroundColor: 'white',
                    fontWeight: 'bold'
                  }}
                  size="small"
                  id="language"
                  value={language}
                  label=""
                  onChange={(e) => handleChange("language", e)}
                  title={
                    languageOptions.find((o: any) => {
                      return o.value == language;
                    })?.name
                  }
                  className="height200"
                >
                  {languageOptions.map((item: any, i: number) => {
                    return (
                      <MenuItem value={item.value} key={item.name + i}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="site-info">
                <h1 className="site-title">{getTranslated("others.jobEvaluationQuestionnaire") || "Job Evaluation Questionnaire"}</h1>

              </div>
              <div>
                <a href={helpLink} target="_blank"> <QuizOutlinedIcon style={{ color: '#fff', cursor: 'pointer', marginLeft: '10px' }} fontSize="large" /></a>
              </div>
            </Grid>
          </Grid>
          <div className="form-wrapper"></div>
        </Container>
      </header>
    </>
  );
};

export default Header;
