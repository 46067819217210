import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormControlFlex } from "../styled";
import { ToolTip } from "./ToolTip";
import { InputAdornment } from "@mui/material";
import { getTranslated } from "../functions/getTranslatedLevel";

interface IFormText {
  updateState: Function;
  data: any;
}

const PurposeOfRequest = ({
  updateState,
  data,
}: IFormText) => {
  //local State for error
  const [validationError, setValidationError] = React.useState(false);

  React.useEffect(() => {
    //required check
    if (data.required != undefined && data.required === true) {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== ""
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }
  }, [data]);

  const InputProps = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
    endAdornment: <InputAdornment position="end">MM USD</InputAdornment>,
  };

  return (
    <>
      <FormControlFlex>
        <div className="label">
          <span>
            {data.label}
            {data.required != undefined && data.required === true && (
              <span style={{ color: "white", marginLeft: 5 }}>*</span>
            )}
          </span>

          {/* {data.toolTipText != undefined ? (
            <ToolTip title={data.toolTipText} />
          ) : (
            ""
          )} */}
        </div>
        <div>
          <FormControl fullWidth>
            <TextField
              style={{ maxWidth: "270px", width: "270px" }}
              disabled={
                data.readOnly != undefined && data.readOnly === true
                  ? true
                  : false
              }
              multiline={data.multiline}
              error={validationError}
              size="small"
              id="outlined-basic"
              label=""
              variant="outlined"
              value={getTranslated(`dynamic.${data.value}`) || data.value}
              inputProps={{ maxLength: 256 }}
              title={getTranslated(`dynamic.${data.value}`) || data.value}
              required
            />
          </FormControl>
        </div>
      </FormControlFlex>
    </>
  );
};
export default PurposeOfRequest;
