import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormControlFlex } from "../styled";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { ToolTip } from "./ToolTip";
import { getTranslated } from "../functions/getTranslatedLevel";

interface IFormDate {
  updateState: Function;
  data: any;
}

const FormDatePicker = ({ updateState, data }: IFormDate) => {
  /*
  props .data

  {
    label: "Purpose of request",
    value: "lore ipsum",
    name:'purpose_of_request',
  }
  */

  //   const [value, setValue] = React.useState<Date | null>(new Date());
  //   const handleChange = (key : any, newValue: Date | null) => {
  //     setValue(newValue);
  //   };
  const [validationError, setValidationError] = React.useState(false);

  const [open, setOpen] = React.useState<boolean>(false);
  const handleChange = (key: any, newValue: any) => {
    updateState({
      [key]: moment(newValue).format("MMMM D,Y"),
    });
  };

  React.useEffect(() => {
    if (data.required != undefined && data.required === true) {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== ""
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }
  }, [data]);

  const preparedValue = data.value || data.required ? data.value : null;

  return (
    <>
      <FormControlFlex>
        <div className="label">
          <span>{data.label}</span>
          {data.required === true && (
            <span style={{ color: "white", marginLeft: 5 }}>*</span>
          )}

        </div>
        <div>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disabled={
                  data.readOnly !== undefined && data.readOnly === true
                    ? true
                    : false
                }
                open={open}
                onClose={() => setOpen(false)}
                inputFormat="MMMM DD,YYYY"
                minDate={
                  data.blockOneMonthBack
                    ? moment(moment.now()).subtract(1, "months")
                    : (data.blockOneDayBack ? moment(moment.now()) : undefined)
                }
                value={preparedValue}
                onChange={(newValue) => handleChange(data.name, newValue)}
                renderInput={(params) => (
                  <TextField
                    style={{ maxWidth: "270px", width: "270px" }}
                    onClick={() => (data.readOnly ? null : setOpen(true))}
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    label=""
                    {...params}
                    error={validationError}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: getTranslated("placeHolder.datePicker") || "Select Date!",
                      readOnly: data.blockOneDayBack ? true : false,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
      </FormControlFlex>
      <FormControlFlex>
        <div className="label">
          <span style={{ fontSize: "x-small", marginTop: "-26px" }}>{data.toolTipText}</span>
        </div>
      </FormControlFlex>
    </>
  );
};
export default FormDatePicker;
