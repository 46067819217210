import * as React from "react";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgressWithLabel from "../../../app/progress-bar/LinearProgressWithLabel";
import Container from "@mui/material/Container";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonsContainer,
} from "../../../app/styled";
import {
  nextStep,
  prevStep,
  goToLastPage,
  goFromLandingPageToForm,
  viewForm,
  updateReqId,
  updateUserId,
  getDraftedData,
  getPreDefinedData,
  submitPositionDetails,
  submitRoleContext,
  getRoleContextForm,
  getFmDraftedData,
  updateFormLockStatus,
  updateAccessToLastPage,
  getTrAdditionalDetails,
  generatePdf,
  createTrHr,
  submitFM,
  getManagerName,
  savePositionDetails,
  getFormStatus,
  updateAuthorize,
  getQueryParams,
  updateFmActiveStep,
  updateClickedOnDraft,
  updateOktaUserData,
  updateOktaUserRole,
  goToLandingPage,
  skipLandingPage,
  updateStepNumber,
  updateLanguage,
  getPositionDetailsMaster,
  updateIsReviewAndSubmit,
  updateAuthorizationFailed
} from "../fm_1/fmOneSlice";
import FmLandingPage from "./FmLandingPage";
import FmRoleContext from "./steps/role-context/FmRoleContext";
import FmPositionDetails from "./steps/position-details/FmPositionDetails";
import { positionDetailsValidate, roleContextValidate } from "./Validation";
import TrEvaluationPage from "./steps/trEvaluationPage/trEvaluationPage";
import Button from "@mui/material/Button";
import { FINAL_STEP_MESSAGE } from "../../../app/constants";
import {
  deleteFileNames,
  validateTrHr,
} from "../../../app/functions/CommonFunctions";
import { useOktaAuth } from "@okta/okta-react";
import { Divider } from "@mui/material";
import { EditButton } from "../../../app/form-inputs/EditButton";
import Header from "../../header/Header";
import { dynamicValue, getTranslated } from "../../../app/functions/getTranslatedLevel";
import PaqSubmitDialog from "../../../app/dialog/PaqSubmitDialog";
import { fetchQueryParams } from "./fmOneAPI";
import Share from "../fmPaqEvaluateNewRole/Share";

const steps = ["POSITION DETAILS", "ROLE CONTEXT"];

const FmOne = () => {
  const HR_Manager = "HR MANAGER";
  const { authState } = useOktaAuth();
  const oktaClaims: any = authState?.idToken?.claims
    ? authState.idToken.claims
    : null;
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useSearchParams();

  //handle Modal variables and methods - START
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isConfirmModal, setConfirmModal] = React.useState<boolean>(false);
  const [errMsg, setErrMsg] = React.useState<string>("");
  const [shareData, setShareData] = React.useState<boolean>(true)

  const handleModalOpen = (msg: string) => {
    setIsError(true)
    setErrMsg(msg);
  }
  const handleModalClose = () => {
    setErrMsg("");
    setIsError(false)
    setConfirmModal(false);
  }
  const handleConfirmModalOpen = (msg: string) => {
    setConfirmModal(true)
    setErrMsg(msg);
  }
  //handle Modal variables and methods - END
  //get data from url query params
  // appDispatch(updateUserId(oktaClaims?.gpid));
  appDispatch(updateOktaUserData(oktaClaims));
  let fmType = queryParams.get("fmType");
  let requestId = queryParams.get("requestId");
  let showDraftedValue = queryParams.get("showDraftedValue");
  let showSummary = queryParams.get("showSummary");
  // fetching query params form Manager not TR
  let userRole: any =
    oktaClaims?.pepapphrjepcroles[0] == "Manager" || oktaClaims?.pepapphrjepcroles?.length === 0
      ? "manager"
      : oktaClaims?.pepapphrjepcroles[0] == "TRUsers"
        ? "tr"
        : oktaClaims?.pepapphrjepcroles[0] == HR_Manager ? HR_Manager : "invalid";
  appDispatch(updateOktaUserRole(userRole));

  const showLandingPage = useSelector((state: any) => state.fm.showLandingPage);
  const sharedWith = useSelector(
    (state: any) => state.fm.sharedWith
  );
  const getRequestDetails = async (payload: any) => {
    let res = await fetchQueryParams(payload);
    if (res.userGPID) {
      appDispatch(updateUserId(res.userGPID));
    }
  }
  React.useEffect(() => {
    if (requestId?.includes("PSW")) {
      if (userRole === "manager" || userRole === HR_Manager || userRole === "tr") {
        let payload = {
          requestId: requestId,
          userGPID: oktaClaims?.gpid,
        };
        getRequestDetails(payload);
        appDispatch(getQueryParams(payload));
        // if (userRole === HR_Manager) {
        //   setTimeout(() => {
        //      appDispatch(updateAuthorize(true));
        //      appDispatch(updateAuthorizationFailed(false));
        //   }, 1000);
        // }
      }
    } else {
      let payload = {
        requestId: requestId,
        userGPID: oktaClaims?.gpid,
      };
      getRequestDetails(payload);
      appDispatch(getQueryParams(payload));
      // if (userRole === HR_Manager) {
      //   setTimeout(() => {
      appDispatch(updateAuthorize(true));
    }
  }, [requestId, oktaClaims?.gpid, userRole, appDispatch, shareData]);

  // getting state
  const fmState = useSelector((state: any) => state.fm);
  // console.log("fmState", fmState);
  const fmActiveStep = useSelector((state: any) => state.fm.fmActiveStep);
  const progress = useSelector((state: any) => state.fm.fmProgress);
  const formLoaderFm = useSelector((state: any) => state.fm.formLoader);
  const formLoaderEC = useSelector((state: any) => state.fm.formLoaderEC);
  const formLoader = formLoaderFm || formLoaderEC
  const hasAccessToLastPage = useSelector(
    (state: any) => state.fm.hasAccessToLastPage
  );
  const showLastPage = useSelector((state: any) => state.fm.showLastPage);
  const formLocked = useSelector((state: any) => state.fm.formLocked);
  const fromSummary = useSelector((state: any) => state.fm.fromSummary);

  React.useEffect(() => {
    if (fmActiveStep === 2) {
      appDispatch(updateFmActiveStep(1));
      let queryString = window.location.search;
      navigate(`/fm${queryString}&showSummary=true`);
    }
  }, [fmActiveStep]);

  React.useEffect(() => {
    const param = queryParams.get('step');
    if (param) {
      // skipLandingPage
      appDispatch(skipLandingPage());
      // update active step
      appDispatch(updateStepNumber(Number(param)))
      // 👇️ delete each query param
      queryParams.delete('step');
      // 👇️ update state after
      setQueryParams(queryParams);
    }
  }, [queryParams])

  //Form datas
  //1. Position details form data
  const positonDetailsFormData = useSelector(
    (state: any) => state.fm.formData.positionDetails
  );
  //2. role context form data
  const roleContextsFormData = useSelector(
    (state: any) => state.fm.formData.roleContext
  );
  const roleContextStaticFormData = useSelector(
    (state: any) => state.fm.formData.roleContextStaticFields
  );
  const functionValue = useSelector(
    (state: any) => state.fm.formData.positionDetails.functionCode
  );
  // trEvaluationStep form data
  const trEvaluationFormData = useSelector(
    (state: any) => state.fm.formData.trEvaluationStep
  );
  // master data
  const masterData = useSelector(
    (state: any) => state.fm.masterData.positionDetails
  );
  const roleContextsMasterData = useSelector(
    (state: any) => state.fm.masterData.roleContext
  );
  // role context FORM
  const roleContextForm = useSelector((state: any) => state.fm.roleContextForm);

  const stepForm = (param: number) => {
    switch (param) {
      case 0:
        return <FmPositionDetails updateFormData={updateFormData} />;
      case 1:
        return <FmRoleContext updateFormData={updateFormData} />;
      default:
        return <FmRoleContext updateFormData={updateFormData} />;
    }
  };

  const stepNameAndEditButton = (step: number) => {
    return <EditButton stepName={getTranslated(`steps.${step}`) || steps[step]} onEdit={() => onEdit(step)} />;
  };

  //Main dispatch
  const updateFormData = (params: any) => {
    appDispatch(params);
  };

  const getFieldNameFromKey = (searchArray: any, key: any) => {
    let name = "";
    searchArray.forEach((data: any) => {
      if (data.value === key) {
        name = data.name;
      }
    });
    return name;
  };

  const generatePdfObject = () => {
    let pdfPayload: any = {};

    let franchiseRelatedFields = [
      {
        value: dynamicValue(getFieldNameFromKey(
          roleContextsMasterData.orgLayers,
          roleContextStaticFormData.organisationLayerId
        )),
        name: getTranslated("step2.organisationLayerId") || "Organization Layer (PEP CEO = 1)",
        subTitle: "",
      },
      {
        value: roleContextStaticFormData.totalBeverageVolume,
        name: getTranslated("step2.totalBeverageVolume") || "Total Beverage Volume (8oz)",
        subTitle: "",
      },
      {
        value: roleContextStaticFormData.nopbt,
        name: getTranslated("step2.nopbt") || "NOPBT ($MM)",
        subTitle: "",
      },
      {
        value: roleContextStaticFormData.systemNetRevenue,
        name: getTranslated("step2.systemNetRevenue") || "System Net Revenue ($MM)",
        subTitle: "",
      }
    ]

    let positionDetailsObject: any = {
      title: getTranslated(`steps.0`) || "Position Details",
      fields: [
        {
          value: getTranslated("step1.jobReEvaluation") || "Job re-evaluation",
          name: getTranslated("step1.purposeOfRequestId") || "Purpose of request",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.incumbentName
            ? positonDetailsFormData.incumbentName
            : "N/A",
          name: getTranslated("step1.incumbentName") || "Employee Name",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.incumbentGPID
            ? positonDetailsFormData.incumbentGPID
            : "N/A",
          name: getTranslated("step1.incumbentGPID") || "Employee GPID",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.currentPositionId
            ? positonDetailsFormData.currentPositionId
            : "N/A",
          name: getTranslated("step1.currentPositionId") || "Current Position ID",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.currentCompensatedLevelId
            ? dynamicValue(getFieldNameFromKey(
              masterData.currentEvaluatedLevels,
              positonDetailsFormData.currentEvaluatedLevelId
            ))
            : "N/A",
          name: getTranslated("step1.currentEvaluatedLevelId") || "Current Level",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.currentCompensatedLevelId
            ? dynamicValue(getFieldNameFromKey(
              masterData.currentCompensatedLevels,
              positonDetailsFormData.currentCompensatedLevelId
            ))
            : "N/A",
          name: getTranslated("step1.currentCompensatedLevelId") || "Current Compensated Level",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.proposedLevels,
            parseInt(positonDetailsFormData.proposedEvaluatedLevelId)
          )),
          name: getTranslated("step1.proposedEvaluatedLevelId") || "Proposed Level",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.proposedCompensatedLevelId
            ? dynamicValue(getFieldNameFromKey(
              masterData.proposedCompensatedLevels,
              parseInt(positonDetailsFormData.proposedCompensatedLevelId)
            ))
            : "N/A",
          name: getTranslated("step1.proposedCompensatedLevelId") || "Proposed Compensated Level",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.managerName,
          name: getTranslated("step1.managerName") || "Manager Name",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.managerGPID,
          name: getTranslated("step1.managerGPID") || "Manager GPID",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.effectiveDate,
          name: getTranslated("step1.effectiveDate") || "Target Effective Date",
          subTitle: "",
        },
        {
          value: getFieldNameFromKey(
            masterData.functions,
            positonDetailsFormData.functionCode
          ),
          name: getTranslated("step1.functionCode") || "Function",
          subTitle: "",
        },
        {
          value: getFieldNameFromKey(
            masterData.subFunctions,
            positonDetailsFormData.subFunctionCode
          ),
          name: getTranslated("step1.subFunctionCode") || "Sub Function",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.jobTitleId,
          name: getTranslated("step1.jobTitleId") || "Current Job Title",
          subTitle: "",
        },
        {
          value: positonDetailsFormData.proposedJobTitleId,
          name: getTranslated("step1.proposedJobTitleId") || "New Position Title",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.countries,
            positonDetailsFormData.countryCode
          )),
          name: getTranslated("step1.countryCode") || "Country",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.sectors,
            positonDetailsFormData.sectorCode
          )),
          name: getTranslated("step1.sectorCode") || "Sector",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.divisions,
            positonDetailsFormData.divisionCode
          )),
          name: getTranslated("step1.divisionCode") || "Division",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.regions,
            positonDetailsFormData.regionCode
          )),
          name: getTranslated("step1.regionCode") || "Region",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.businessUnits,
            positonDetailsFormData.businessUnitCode
          )),
          name: getTranslated("step1.businessUnitCode") || "Business Unit",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.marketUnits,
            positonDetailsFormData.marketUnitCode
          )),
          name: getTranslated("step1.marketUnitCode") || "Market Unit",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            masterData.workLocations,
            positonDetailsFormData.workLocationCode
          )),
          name: getTranslated("step1.workLocationCode") || "Work Location",
          subTitle: "",
        },
      ],
    };
    let roleContextObject: any = {
      title: getTranslated(`steps.1`) || "Role Context",
      fields: [
        {
          value: roleContextStaticFormData.rolePurpose,
          name: getTranslated("step2.rolePurpose") || "Role Purpose",
          subTitle: "",
        },
        {
          value: roleContextStaticFormData.comparableRoles,
          name: getTranslated("step2.comparableRoles") || "Comparable Roles",
          subTitle: "",
        },
        {
          value: dynamicValue(getFieldNameFromKey(
            roleContextsMasterData.businessContexts,
            roleContextStaticFormData.businessContextForRoleReevaluationId
          )),
          name: getTranslated("step2.businessContextForRoleReevaluationId") || "Please select the business context that is the primary driver for re-evaluating this role",
          subTitle: "",
        },
        {
          value: roleContextStaticFormData.otherDescription,
          name: getTranslated("step2.otherDescription") || "Please provide more details on the business context",
          subTitle: "",
        },
        {
          value: roleContextStaticFormData.numberOfDirectReports,
          name: getTranslated("step4.numberOfDirectReports") || "Number of Direct Reports",
          subTitle: "",
        },
      ],
    };

    if (functionValue == 'JF009') {
      roleContextObject["fields"].push(...franchiseRelatedFields)
    }

    roleContextsFormData.forEach((data: any) => {
      let value = "";
      let name = "";
      let subTitle = "";
      roleContextForm.forEach((elementFramework: any) => {
        subTitle = elementFramework.framework ? getTranslated(`dynamic.${elementFramework.framework}`) || elementFramework.framework : ""; // update subtitle
        elementFramework.subFramework.forEach((elementSubFramework: any) => {
          elementSubFramework.criterias.forEach((elementCriteria: any) => {
            if (data.criteriaId === elementCriteria.criteriaCode) {
              name = getTranslated(`dynamic.${elementCriteria.criteriaInfo}`) || elementCriteria.criteriaInfo; // update question
              elementCriteria.options.forEach((elementOptions: any) => {
                if (data.answerId === elementOptions.optionCode) {
                  value = getTranslated(`dynamic.${elementOptions.options}`) || elementOptions.options;// update value
                  roleContextObject["fields"].push({
                    value: value,
                    name: name,
                    subTitle: subTitle,
                  });
                } else if (data.answerId === 0) {
                  roleContextObject["fields"].push({
                    value: "N/A",
                    name: name,
                    subTitle: subTitle,
                  });
                }
              });
            }
          });
        });
      });
    });
    pdfPayload.requestId = fmState.requestId;
    pdfPayload.formDetails = {};
    pdfPayload.formDetails.positionDetails = positionDetailsObject;
    pdfPayload.formDetails.roleContext = roleContextObject;
    pdfPayload.formType = "Functional Model(FM)";
    pdfPayload.user = fmState.userId;
    let commentObject = {
      value: fmState.comments ? fmState.comments : "N/A",
      name: getTranslated("step3.comment") || "Comments (if any)",
      subTitle: "",
    };
    pdfPayload.formDetails.roleContext.fields.push(commentObject);
    appDispatch(generatePdf(pdfPayload));
  };

  const handleNextAndSubmit = (saveDraft = false, isReviewAndSubmit = false) => {
    let moveToNextStep = false;
    appDispatch(updateClickedOnDraft(saveDraft));
    appDispatch(updateIsReviewAndSubmit(isReviewAndSubmit));
    //Common value
    let insertPayload: any = {
      requestId: fmState.requestId,
      status: "Draft",
      user: fmState.userId,
    };
    //form submit with draft state and
    if (fmActiveStep === 0) {
      const { submittedDate, ...rest } = positonDetailsFormData;
      insertPayload = { ...insertPayload, ...rest };
      if (saveDraft) {
        appDispatch(savePositionDetails(insertPayload));
      } else if (positionDetailsValidate(insertPayload)) {
        appDispatch(submitPositionDetails(insertPayload));
        moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
    }
    if (fmActiveStep === 1) {
      let answers = {
        answer: roleContextsFormData,
      };
      let comments = {
        comments: fmState.comments,
      };
      insertPayload = { ...insertPayload, ...answers, ...comments, roleContextStaticFields: deleteFileNames({ ...roleContextStaticFormData }) };
      let payload = {
        insertPayload: insertPayload,
        fmType: fmType,
      };
      if (saveDraft || roleContextValidate(roleContextStaticFormData, roleContextsMasterData, functionValue)) {
        appDispatch(submitRoleContext(payload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
    }
    return moveToNextStep;
  };

  const handleLandingPageToForm = () => {
    appDispatch(goFromLandingPageToForm());
  };

  React.useEffect(() => {
    if (fmState.authorize && fmState.userId) {
      if (fmState.pdfGenerated) {
        let answers = {
          answer: roleContextsFormData,
        };
        let comments = {
          comments: fmState.comments,
        };
        let insertPayload: any = {
          requestId: fmState.requestId,
          status: "Draft",
          user: fmState.userId,
        };
        insertPayload.status = "Submitted";
        insertPayload = { ...insertPayload, ...answers, ...comments, roleContextStaticFields: deleteFileNames({ ...roleContextStaticFormData }) };
        let payload = {
          insertPayload: insertPayload,
          fmType: fmType,
        };
        appDispatch(submitFM(payload));
      }
    }
  }, [fmState.pdfGenerated, fmState.authorize, fmState.userId]);

  const handleSubmit = () => {
    generatePdfObject();
  };

  const handlePrev = () => {
    if (fmActiveStep > 0) {
      appDispatch(prevStep());
    }
  };

  const handleLastNext = () => {
    let trAdditionalDetailsPayload = {
      functionCode: fmState.formData.positionDetails.functionCode,
      subFunctionCode: fmState.formData.positionDetails.subFunctionCode,
      requestId: fmState.requestId,
    };
    appDispatch(getTrAdditionalDetails(trAdditionalDetailsPayload));
    // appDispatch(goToLastPage(!showLastPage));
    // appDispatch(getEvaluatedLevel(fmType));
  };

  const handleViewForm = () => {
    appDispatch(viewForm());
    appDispatch(goToLastPage(!showLastPage));
  };

  const handleFormLocked = () => {
    // appDispatch(updateFormLocked(!formLocked));
    let newStatus = formLocked === true ? "Draft" : "Submitted";
    let payload = {
      requestId: fmState.requestId,
      status: newStatus,
      // user: fmState.userId,
      user: oktaClaims?.gpid,
    };
    appDispatch(updateFormLockStatus(payload));
  };

  const handleNextForTR = () => {
    appDispatch(nextStep());
  };

  const handleSubmitLastPage = () => {
    // let evaluatedLevelId = findLevelObjByName(
    //   trEvaluationFormData.evaluatedLevel,
    //   fmState.masterData.positionDetails.currentEvaluatedLevels
    // ).value;
    let payload = {
      evaluatedBy: trEvaluationFormData.evaluatedBy,
      evaluatedLevel: trEvaluationFormData.evaluatedLevel,
      evaluationMethod: trEvaluationFormData.evaluationMethod,
      futureJobCode: trEvaluationFormData.futureJobCode,
      requestorName: trEvaluationFormData.requestorName,
      snowCaseId: trEvaluationFormData.snowCaseId,
      requestId: fmState.requestId,
      user: oktaClaims?.gpid,
    };
    //if (validateTrHr(payload)) {
    appDispatch(createTrHr(payload));
    //} else {
    //handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
    //}
  };

  const handlePreviewAndSubmit = () => {
    handleNextAndSubmit(true, true);
    appDispatch(updateFmActiveStep(2));
  };

  const onEdit = (stepNumber: number) => {
    appDispatch(updateFmActiveStep(stepNumber));
    let queryString = window.location.search;
    navigate(`/fm${queryString.replace("&showSummary=true", "")}`);
  };

  //use effect
  React.useEffect(() => {
    if (fmState.authorize) {
      // Update data from query params
      appDispatch(updateReqId(requestId));
      // fetch Role context form
      appDispatch(getRoleContextForm(fmType));
      //check form status
      appDispatch(getFormStatus(requestId));

      if (showDraftedValue !== null && showDraftedValue === "yes") {
        //get drafted value
        appDispatch(getDraftedData(requestId));
      } else {
        //get pre defined data
        let payload = {
          incumbentGPID: positonDetailsFormData.incumbentGPID,
          requestId: requestId,
        };
        updateFormData(getPreDefinedData(payload));
      }
      updateFormData(getPositionDetailsMaster());
      //------------- to be DELETED-----------------------------
      if (userRole !== null && userRole === "tr") {
        appDispatch(updateAccessToLastPage(true));
        appDispatch(getDraftedData(requestId));
        appDispatch(getFmDraftedData(requestId)); 
      }
      // ---------------------------------------------------------
    }
  }, [fmState.authorize]);

  React.useEffect(() => {
    if (fmState.authorize) {
      if (
        showDraftedValue !== null &&
        showDraftedValue === "yes" &&
        fmActiveStep === 1
      ) {
        //get drafted value
        appDispatch(getFmDraftedData(requestId));
      }
    }
  }, [fmActiveStep, fmState.authorize]);

  React.useEffect(() => {
    if (fmState.authorize && positonDetailsFormData.managerGPID) {
      if (positonDetailsFormData.managerGPID) {
        if (positonDetailsFormData.managerGPID != null) {
          appDispatch(getManagerName(positonDetailsFormData.managerGPID));
        }
      }
    }
  }, [positonDetailsFormData.managerGPID, fmState.authorize]);

  const handleExit = () => {
    appDispatch(goToLandingPage());
    let queryString = window.location.search;
    navigate(`/fm${queryString.replace("&showSummary=true", "")}`);
    window.location.reload();
  };

  React.useEffect(() => {
    if (fmState.status === "Draft" && showDraftedValue !== "yes") {
      // redirect to drafted page
      navigate(
        `/fm?fmType=${fmType}&requestId=${requestId}&showDraftedValue=yes`
      );
      window.location.reload();
    } else if (fmState.status === "Submitted" && showDraftedValue !== "yes") {
      // appDispatch(updateFormStatusFetched(false));
    }
  });

  return <React.Fragment>
    <Header updateLanguage={updateLanguage} state={fmState} />
    {isError && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} />}
    {isConfirmModal && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} handleModalSubmit={handleSubmit} isConfirm={true} />}
    {!fmState.authorizationFailed && !fmState.authorize ? (
      <div>{getTranslated("alert.checkingAccess") || "Checking access"} ...</div>
    ) : fmState.authorizationFailed ? (
      <div>{getTranslated("alert.noAccess") || "User do not have access to this form"} !</div>
    ) : userRole === "invalid" ? (
      <div>{getTranslated("alert.unableToAccess") || "Unable to access this form"} !</div>
    ) : (userRole === "tr" && (fmState.status === null || !fmState.caseIdFetched)) ? (
      <div>{getTranslated("alert.unableToAccess") || "Unable to access this form"} !</div>
    ) : fmState.formStatusFetched === true ? (
      (fmState.status === "Submitted" ||
        fmState.caseId ||
        fmState.status === "Completed") &&
        userRole !== "tr" ? (
        <div style={{ fontFamily: "initial", textAlign: "center" }}>
          {fmState.status === "Completed" ? (
            <h3>{getTranslated("alert.alreadyCompleted") || "Form is already completed."}</h3>
          ) : (
            <h3>{getTranslated("alert.formSubmitted") || "Form is successfully submitted."}</h3>
          )}
          <h3>
            {(getTranslated("alert.caseId") || "Case-Id") + " : "}
            {fmState.status === "Submitted" || fmState.status === "Completed" ? (
              <span
                dangerouslySetInnerHTML={{ __html: fmState.caseIdFetched }}
              ></span>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: fmState.caseId }}></span>
            )}
          </h3>
        </div>
      ) : userRole == "tr" && fmState.trFormSubmitted ? (
        <div style={{ fontFamily: "initial", textAlign: "center" }}>
          {/* "TR Form submit success" */}
          <h3>{getTranslated("toast.formSubmitted") || "Form submitted successfully"}</h3>
        </div>
      ) : (
        <div className="page-wrapper">
          <Container maxWidth="lg" className="container-padding-vertical">
            <div className="form-wrapper">
              {showLandingPage ? (
                <FmLandingPage handleNextClick={handleLandingPageToForm} />
              ) : showLastPage ? (
                <Grid container spacing={2}>
                  {/*--FORM CONTENT---*/}
                  <Grid item xs={12} md={12} mt={2} className="form-conatiner">
                    {formLoader && (
                      <div className="loader-wrapper">
                        <CircularProgress />
                      </div>
                    )}
                    <TrEvaluationPage updateFormData={updateFormData} />
                  </Grid>
                  {/*--FORM BOTTOM---*/}
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <ButtonsContainer style={{ justifyContent: "right" }}>
                          <ButtonPrimary onClick={() => handleViewForm()}>
                            {getTranslated("buttons.viewForm") || "View Form"}
                          </ButtonPrimary>
                          {formLocked ? (
                            <ButtonPrimary onClick={() => handleFormLocked()}>
                              {getTranslated("buttons.unlockForm") || "Unlock Form"}
                            </ButtonPrimary>
                          ) : (
                            <Button variant="contained" disabled>
                              {getTranslated("buttons.unlockForm") || "Unlock Form"}
                            </Button>
                          )}
                          {!formLocked ? (
                            <ButtonPrimary onClick={() => handleFormLocked()}>
                              {getTranslated("buttons.lockForm") || "Lock Form"}
                            </ButtonPrimary>
                          ) : (
                            <Button variant="contained" disabled>
                              {getTranslated("buttons.lockForm") || "Lock Form"}
                            </Button>
                          )}
                          <ButtonPrimary
                            style={{ marginRight: 0 }}
                            onClick={() => handleSubmitLastPage()}
                          >
                            {/* Save Form */}
                            {getTranslated("buttons.submit") || "Submit"}
                          </ButtonPrimary>
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : showSummary ? (
                <div>
                  {getTranslated("others.reviewQuestionnaire") || "Please review questionnaire responses before submitting."}
                  <Divider style={{ marginTop: "0.5em" }} />
                  {stepNameAndEditButton(0)}
                  <FmPositionDetails
                    updateFormData={updateFormData}
                    disabled={showSummary === "true"}
                  />
                  <Divider style={{ marginTop: "1em" }} />
                  {stepNameAndEditButton(1)}
                  <FmRoleContext
                    updateFormData={updateFormData}
                    disabled={showSummary === "true"}
                  />
                  <Divider style={{ marginTop: "1em" }} />
                  <Grid item xs={12} md={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <LinearProgressWithLabel value={100} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ButtonsContainer style={{ justifyContent: "right" }}>
                          <ButtonSecondary
                            onClick={() => handleExit()}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated("buttons.exit") || "Exit"}
                          </ButtonSecondary>
                          <ButtonPrimary
                            onClick={() => handleConfirmModalOpen(getTranslated("alert.finalStepMessage") || FINAL_STEP_MESSAGE)}
                            style={{ marginRight: 0 }}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {formLoader ? (getTranslated("buttons.submitting") || "Submitting") + "..." : getTranslated("buttons.submit") || "Submit"}
                          </ButtonPrimary>
                          {formLoader && (
                            <CircularProgress
                              size={20}
                              style={{ marginLeft: 10 }}
                            />
                          )}
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Grid container spacing={2}>
                  {/*--STEPER START---*/}
                  <Grid item xs={0} md={3}></Grid>
                  <Grid item xs={12} md={6} mt={2} mb={1}>
                    <Stepper activeStep={fmActiveStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} style={{ fontSize: 18 }}>
                              {getTranslated(`steps.${index}`)}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                  {/* <Grid item xs={12} md={3}><Share requestId={requestId} shareArray={JSON.parse(sharedWith)} from="fmOne" setShareData={setShareData} shareData={shareData} /></Grid> */}
                  <Grid item xs={0} md={3}></Grid>
                  {/*--FORM CONTENT---*/}
                  <Grid item xs={12} md={12} mt={2} className="form-conatiner">
                    {formLoader && (
                      <div className="loader-wrapper">
                        <CircularProgress />
                      </div>
                    )}

                    {stepForm(fmActiveStep)}
                  </Grid>
                  {/*--FORM BOTTOM---*/}
                  <Grid item xs={12} md={12} mt={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <LinearProgressWithLabel value={progress} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ButtonsContainer style={{ justifyContent: "right" }}>
                          {fromSummary && (
                            <ButtonSecondary
                              style={{ marginRight: 0 }}
                              onClick={() => handlePreviewAndSubmit()}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.reviewSubmit') || 'Review & Submit'}
                            </ButtonSecondary>
                          )}
                          {userRole === "tr" ? (
                            <span></span>
                          ) : (
                            <ButtonSecondary
                              onClick={() => handleNextAndSubmit(true)}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.saveDraft') || 'Save Draft'}
                            </ButtonSecondary>
                          )}
                          <ButtonSecondary
                            onClick={() => handleExit()}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated('buttons.exit') || 'Exit'}
                          </ButtonSecondary>
                          {fmActiveStep > 0 ? (
                            <ButtonPrimary
                              onClick={() => handlePrev()}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.prev') || 'Previous'}
                            </ButtonPrimary>
                          ) : (
                            ""
                          )}
                          {hasAccessToLastPage ? (
                            fmActiveStep === steps.length - 1 ? (
                              <ButtonPrimary
                                onClick={() => handleLastNext()}
                                style={{ marginRight: 0 }}
                                disabled={formLoader ? true : false}
                                className={formLoader && "disabledButton"}
                              >
                                {getTranslated('buttons.next') || 'Next'}
                              </ButtonPrimary>
                            ) : (
                              <ButtonPrimary
                                onClick={() => handleNextForTR()}
                                style={{ marginRight: 0 }}
                                disabled={formLoader ? true : false}
                                className={formLoader && "disabledButton"}
                              >
                                {getTranslated('buttons.next') || 'Next'}
                              </ButtonPrimary>
                            )
                          ) : (
                            <ButtonPrimary
                              onClick={() => handleNextAndSubmit()}
                              style={{ marginRight: 0 }}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.next') || 'Next'}
                            </ButtonPrimary>
                          )}
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </Container>
        </div>
      )
    ) : (
      <div></div>
    )}
  </React.Fragment>;
};
export default FmOne;

// call this only for manager (fetch query params)
//look for draft -- no user id there as well
// does fmType and showDraftedValue is in URL params?
